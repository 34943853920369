module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/home/chris/visioncortex.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Vision Cortex","SymCode","Impression","VTracer"],"mdPlugins":[],"hastPlugins":[],"ignore":["README.md"],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/home/chris/visioncortex.github.io/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":"/docs","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Vision Cortex","description":"Semantic Computer Vision","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/home/chris/visioncortex.github.io","templates":"/home/chris/visioncortex.github.io/node_modules/docz-core/dist/templates","docz":"/home/chris/visioncortex.github.io/.docz","cache":"/home/chris/visioncortex.github.io/.docz/.cache","app":"/home/chris/visioncortex.github.io/.docz/app","appPackageJson":"/home/chris/visioncortex.github.io/package.json","appTsConfig":"/home/chris/visioncortex.github.io/tsconfig.json","gatsbyConfig":"/home/chris/visioncortex.github.io/gatsby-config.js","gatsbyBrowser":"/home/chris/visioncortex.github.io/gatsby-browser.js","gatsbyNode":"/home/chris/visioncortex.github.io/gatsby-node.js","gatsbySSR":"/home/chris/visioncortex.github.io/gatsby-ssr.js","importsJs":"/home/chris/visioncortex.github.io/.docz/app/imports.js","rootJs":"/home/chris/visioncortex.github.io/.docz/app/root.jsx","indexJs":"/home/chris/visioncortex.github.io/.docz/app/index.jsx","indexHtml":"/home/chris/visioncortex.github.io/.docz/app/index.html","db":"/home/chris/visioncortex.github.io/.docz/app/db.json"}},
    }]
