// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-impression-mdx": () => import("./../../../../src/impression.mdx" /* webpackChunkName: "component---src-impression-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-reversi-mdx": () => import("./../../../../src/reversi.mdx" /* webpackChunkName: "component---src-reversi-mdx" */),
  "component---src-shapesense-mdx": () => import("./../../../../src/shapesense.mdx" /* webpackChunkName: "component---src-shapesense-mdx" */),
  "component---src-symcode-mdx": () => import("./../../../../src/symcode.mdx" /* webpackChunkName: "component---src-symcode-mdx" */),
  "component---src-vtracer-mdx": () => import("./../../../../src/vtracer.mdx" /* webpackChunkName: "component---src-vtracer-mdx" */)
}

